import React, { useState } from "react";
import DetailData from "./data.view";
import AlmondList from "../almondList";
import EntamadoraList from "../entamadoraList";
import LagarList from "../lagarList";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { TYPE_ALMOND, TYPE_ENTAMADO, TYPE_LAGAR } from "../../../constants";

const queryClient = new QueryClient();

const DataContainer = () => {
  const [data, setData] = useState(null);
  const [type, setType] = useState(TYPE_ALMOND.code);
  const handlerOnChangeType = (type) => {
    setType(type);
  };

  return (
    <>
      <DetailData onSelectType={handlerOnChangeType} typeSelected={type} />
      <div className="data">
        {type === TYPE_ALMOND.code && (
          <QueryClientProvider client={queryClient}>
            <AlmondList className="marg-top-1rem" filters={{}} />
          </QueryClientProvider>
        )}
        {type === TYPE_ENTAMADO.code && (
          <QueryClientProvider client={queryClient}>
            <EntamadoraList className="marg-top-1rem" filters={{}} />
          </QueryClientProvider>
        )}
        {type === TYPE_LAGAR.code && (
          <QueryClientProvider client={queryClient}>
            <LagarList className="marg-top-1rem" filters={{}} />
          </QueryClientProvider>
        )}
      </div>
    </>
  );
};

DataContainer.propTypes = {};

export default DataContainer;
