import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { useTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./companyForm.data.form";
import InputText from "../../../utils/InputText";
// import InputSelect from "../../../utils/InputSelect";
import Breadcrumb from "../../../utils/breadCrumbs";
import PATHS from "../../../../constants/paths";
import { useNavigate } from "react-router-dom";
import {
  SelectContainer,
  SelectContainer2,
  RowSelect,
  Button,
  Container,
  SubTitle,
  ButtonSelect,
} from "./companyForm.styled";

const CompanyForm = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleSubmit, edit } = props;
  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.company.title"), path: PATHS.LIST_COMPANY },
    { label: t(`forms.company.${edit ? "edit" : "new"}`) },
  ];

  const cancelAction = () => {
    navigate(PATHS.LIST_COMPANY);
  };

  return (
    <>
      <div className="row justify-content-center mt-4 mt-md-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">
            {t(`forms.company.${edit ? "edit" : "new"}`)}
          </h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center  ">
        <div className="col-12 col-md-11">
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="row">
              <div className="col-5">
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="name"
                      className="form-control"
                      labelField={t("forms.company.labels.name")}
                      name="name"
                      component={InputText}
                      fieldType="text"
                      placeholder=""
                      mandatory
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-2">
                    <Field
                      id="cif"
                      className="form-control"
                      labelField={t("forms.company.labels.cif")}
                      name="cif"
                      component={InputText}
                      fieldType="text"
                      placeholder=""
                      mandatory
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-10 col-sm-11 text-center mt-5 mb-5">
                <button
                  type="button"
                  onClick={() => cancelAction()}
                  className="btn btn-md btn-secondary me-2"
                >
                  {t("forms.company.buttons.cancel")}
                </button>
                <button type="submit" className="btn btn-md btn-primary">
                  {t("forms.company.buttons.save")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

CompanyForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

const CompanyFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "CompanyForm",
})(CompanyForm);

export default CompanyFormF;
