import { store } from "../store/index";
import {
  userLogout,
  getProfile,
  loginExt,
  setAuthToken,
  setAuthUser,
  updatePassword,
} from "../store/actions/AuthActions";

export const profile = async () => {
  const result = await store.dispatch(getProfile());
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const logout = async () => {
  await store.dispatch(userLogout());

  return null;
};

export const login = async (user, password) => {
  const obj = {
    userName: user,
    password,
  };
  const result = await store.dispatch(loginExt(obj));
  if (result?.payload?.access_token) {
    await store.dispatch(setAuthUser(result.payload.user));
    await store.dispatch(
      setAuthToken({
        access_token: result.payload.access_token,
        token_expiration: result.payload.token_expiration,
      })
    );
    return result.payload.user.changePass ? "CHANGE" : "OK";
  }

  return null;
};

/**
 * Update password
 */
export const changePassword = async (values) => {
  const objSend = {
    password: values.password,
  };

  const result = await store.dispatch(updatePassword(objSend));
  if (result && result.payload) {
    return "OK";
  }
  return null;
};
