import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import ProviderForm from "./providerForm.view";
import * as ProviderService from "../../../../services/ProviderService";
import * as CompanyService from "../../../../services/CompanyService";
import { useNavigate } from "react-router-dom";
import PATHS from "../../../../constants/paths";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import { useTranslation } from "react-i18next";

const ProviderFormContainer = ({ uuid }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [companiesBack, setCompaniesBack] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [companiesSelected, setCompaniesSelected] = useState([]);
  const [initValues, setInitValues] = useState({});
  const [data, setData] = useState(null);
  const [filter, setFilter] = useState("");

  const setInitialValues = useCallback(async (provider, listCompanies) => {
    const data = {
      name: provider.name,
      userName: provider.userName,
    };
    if (provider.companiesUuids && provider.companiesUuids.length > 0) {
      const newSelected = [...companiesSelected];
      const newAllowed = [...listCompanies];
      for (const company of provider.companiesUuids) {
        const index = newAllowed.findIndex((obj) => obj.uuid === company);
        if (index !== -1) {
          newSelected.push(newAllowed[index]);
          newAllowed.splice(index, 1);
        }
      }
      setCompaniesSelected(arrayOrder(newSelected, "cif"));
      setCompanies(arrayOrder(newAllowed, "cif"));
    }

    setInitValues(data);
  }, []);

  const loadData = useCallback(async () => {
    const resultCompanies = await CompanyService.listAllCompany();
    if (resultCompanies && resultCompanies.length > 0) {
      setCompanies(arrayOrder(resultCompanies, "cif"));
    }
    if (uuid) {
      const result = await ProviderService.getProvider(uuid);
      if (result) {
        setInitialValues(result, resultCompanies);
        setData(result);
      }
    }
  }, [setInitialValues, uuid]);

  // useEffect(() => {
  //   setInitialValues(data);
  // }, [companies]);

  useEffect(() => {
    loadData();
  }, []);

  const handleSubmit = async (values) => {
    let title = "";
    let text = "";
    let icon = "";

    try {
      const okAction = () => {
        navigate(PATHS.LIST_PROVIDER);
      };

      let result;
      if (uuid) {
        result = await ProviderService.updateProvider(
          values,
          companiesSelected,
          uuid
        );
      } else {
        result = await ProviderService.register(values, companiesSelected);
      }
      if (result) {
        title = t("forms.provider.messages.register.title");
        text = t("forms.provider.messages.register.success");
        icon = "success";
      }
      showDialog(title, text, icon, false, "OK", false, okAction);
    } catch (error) {
      // console.log("Error", error);
    }
  };

  const handlerOnSelectCompany = async (company) => {
    const newSelected = [...companiesSelected];
    newSelected.push(company);
    const newAllowed = [...companies];
    const index = newAllowed.findIndex((obj) => obj.cif === company.cif);
    if (index !== -1) {
      newAllowed.splice(index, 1);
    }
    setCompaniesSelected(arrayOrder(newSelected, "cif"));
    setCompanies(arrayOrder(newAllowed, "cif"));
    setFilter("");
    setCompaniesBack([]);
  };

  const handlerOnSelectAllCompany = async () => {
    const newSelected = [...companiesSelected, ...companies];
    setCompaniesSelected(arrayOrder(newSelected, "cif"));
    setCompanies([]);
    setFilter("");
    setCompaniesBack([]);
  };

  const handlerOnDeleteCompany = async (company) => {
    const newAllowed = [...companies];
    newAllowed.push(company);

    const newSelected = [...companiesSelected];
    const index = newSelected.findIndex((obj) => obj.cif === company.cif);
    if (index !== -1) {
      newSelected.splice(index, 1);
    }
    setCompaniesSelected(arrayOrder(newSelected, "cif"));
    setCompanies(arrayOrder(newAllowed, "cif"));
  };

  const handlerOnDeleteAllCompany = async () => {
    const newSelected = [...companiesSelected, ...companies];
    setCompaniesSelected([]);
    setCompanies(arrayOrder(newSelected, "cif"));
  };

  const arrayOrder = (array, sortBy) => {
    return array.sort((a, b) => {
      if (a[sortBy] < b[sortBy]) return -1;
      if (a[sortBy] > b[sortBy]) return 1;
      return 0;
    });
  };

  const filterCompany = (value) => {
    if (value.length > 0) {
      const regex = new RegExp(value, "i"); // 'i' indica que no distingue entre mayúsculas y minúsculas
      const toFilter = [...companies];
      const newCompanies = toFilter.filter((comp) => regex.test(comp.name));
      setFilter(value);
      setCompaniesBack(newCompanies);
    } else {
      setCompaniesBack([]);
    }
  };

  return (
    <ProviderForm
      initialValues={initValues}
      onSubmit={handleSubmit}
      edit={uuid ? true : false}
      companies={companies}
      companiesSelected={companiesSelected}
      onSelect={handlerOnSelectCompany}
      onDelete={handlerOnDeleteCompany}
      onSelectAll={handlerOnSelectAllCompany}
      onDeleteAll={handlerOnDeleteAllCompany}
      filterCompany={filterCompany}
      companiesBack={companiesBack}
      filter={filter}
    />
  );
};

ProviderFormContainer.propTypes = {};

export default ProviderFormContainer;
