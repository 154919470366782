import styled from "styled-components";

export const ButtonAction = styled.button`
  margin-right: 2px;
  color: #f9ae17;
  font-size: 1rem;
  border: 0px;
  background: transparent;
  width: max-content;

  &:hover {
    color: #ff9800;
    font-size: 1rem;
  }
`;

export const Icon = styled.i`
  margin-right: 10px;
`;

export const ButtonClose = styled.button`
  font-size: 1rem;
  border: 0px;
  background: transparent;
`;

export const ResumeTitle = styled.div`
  background-color: #203b34;
  color: #f9ae17;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  border-left: ${({ $first }) =>
    $first ? "1px solid #000" : "0px solid #000"};
`;

export const ResumeCell = styled.div`
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  border-left: ${({ $first }) =>
    $first ? "1px solid #000" : "0px solid #000"};
`;
