export const converToTime = (milisegundos) => {
  // Calcula el número de horas
  const horas = Math.floor(milisegundos / 3600000);
  // Calcula el número de minutos restantes
  const minutosRestantes = Math.floor((milisegundos % 3600000) / 60000);
  // Calcula el número de segundos restantes
  const segundosRestantes = Math.floor(
    ((milisegundos % 3600000) % 60000) / 1000
  );

  const tiempoFormateado =
    pad(horas, 2) +
    ":" +
    pad(minutosRestantes, 2) +
    ":" +
    pad(segundosRestantes, 2);

  return tiempoFormateado;
};

const pad = (num, size) => {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
};

export const roundAndFix = (n, d) => {
  var m = Math.pow(10, d);
  return isNaN(Math.round(n * m) / m) ? 0 : Math.round(n * m) / m;
};

export const formatearNumero = (
  numero,
  decimales = 2,
  separadorMiles = ".",
  separadorDecimales = ",",
  simboloMoneda = ""
) => {
  // Convertimos el número a un string con el número de decimales especificado
  let partes = numero.toFixed(decimales).split(".");

  // Formateamos la parte entera con el separador de miles
  partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, separadorMiles);

  // Unimos la parte entera y decimal con el separador de decimales
  let numeroFormateado = partes.join(separadorDecimales);

  // Añadimos el símbolo de moneda si es necesario
  return simboloMoneda
    ? simboloMoneda + " " + numeroFormateado
    : numeroFormateado;
};
