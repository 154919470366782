import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import UploadForm from "./uploadForm.view";
import * as UploadService from "../../../../services/UploadService";
import { useNavigate } from "react-router-dom";
import PATHS from "../../../../constants/paths";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import { useTranslation } from "react-i18next";

const UploadFormContainer = ({ uuid }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [typeSelected, setTypeSelected] = useState(null);

  const handleSubmit = async (file) => {
    let title = "";
    let text = "";
    let icon = "";

    try {
      const okAction = () => {
        navigate(PATHS.LIST_UPLOAD);
      };

      let result;
      if (file && typeSelected) {
        result = await UploadService.register(file, typeSelected);
      }
      if (result) {
        title = t("forms.upload.messages.register.title");
        text = t("forms.upload.messages.register.success");
        icon = "success";
        showDialog(title, text, icon, false, "OK", false, okAction);
      }
      // else {
      //   title = t("forms.upload.messages.register.title");
      //   text = t("forms.upload.messages.register.error");
      //   icon = "error";
      // }
      //
    } catch (error) {
      // console.log("Error", error);
    }
  };

  const handleOnSelectType = async (value) => {
    setTypeSelected(value);
  };

  return (
    <UploadForm onSubmit={handleSubmit} onSelectType={handleOnSelectType} />
  );
};

UploadFormContainer.propTypes = {};

export default UploadFormContainer;
