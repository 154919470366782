import React from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { withTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./companyFilter.data.form";
import InputText from "../../../utils/InputText";

const MoveFlowsFilterForm = (props) => {
  const { t, handleSubmit, onClean } = props;

  return (
    <div className="row no-margin">
      <div className="col-12 mb-3">
        <div className="accordion" id="stopFilter">
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                {t("forms.company.filter")}
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#stopFilter"
            >
              <div className="accordion-body">
                <form
                  className="form-horizontal"
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-3">
                      <Field
                        id="cif"
                        className="form-control"
                        labelField={t("forms.company.labels.cif")}
                        name="cif"
                        component={InputText}
                        fieldType="text"
                        placeholder=""
                      />
                    </div>
                    <div className="col-3">
                      <Field
                        id="name"
                        className="form-control"
                        labelField={t("forms.company.labels.name")}
                        name="name"
                        component={InputText}
                        fieldType="text"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary me-2"
                      >
                        <i className="fas fa-search"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-lg btn-secondary"
                        onClick={() => onClean(props.resetForm)}
                        title={t("cleanFilter")}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MoveFlowsFilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onClean: PropTypes.func,
  filtered: PropTypes.bool,
};

const MoveFlowsFilterFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: validationDataModel,
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "MoveFlowsFilterForm",
})(withTranslation()(MoveFlowsFilterForm));

export default MoveFlowsFilterFormF;
