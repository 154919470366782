import { createAction } from "redux-actions";
import { apiGet, apiPost, apiPut } from "../../api/index";
import { urlProviderSecured } from "../../api/urls";
import { PAGE_SIZE_10 } from "../../constants/index";

const generateFilterQuery = ({ filters, page, pageSize, sort }) => {
  let filter = "?";

  page ? (filter += "page=" + page) : (filter += "page=1");
  pageSize
    ? (filter += "&pageSize=" + pageSize)
    : (filter += "&pageSize=" + PAGE_SIZE_10);

  if (filters) {
    if (filters.name !== undefined) filter += "&name=" + filters.name;
    if (filters.userName !== undefined)
      filter += "&userName=" + filters.userName;
  }
  if (sort) filter += "&sort=" + sort;
  return filter;
};

/**
 * Filter list orders.
 */
export const filterProviderAdm = createAction("FILTER_ITEMS", (filter, param) =>
  apiGet(urlProviderSecured + generateFilterQuery(filter), param, true)()
);

/**
 * Filter list orders.
 */
export const get = createAction("GET_PROVIDER", (param) =>
  apiGet(urlProviderSecured, param, true)()
);

/**
 * Create provider.
 */
export const createProvider = createAction("REGISTER_PROVIDER", (obj) =>
  apiPost(urlProviderSecured, obj, undefined, true)()
);

/**
 * Update provider.
 */
export const update = createAction("UPDATE_PROVIDER", (obj, param) =>
  apiPut(urlProviderSecured, obj, param, true)()
);

// /**
//  * Filter list orders.
//  */
// export const filterAllItems = createAction("FILTER_ALL_ITEM", (filter) =>
//   apiGet(urlItemsSecured + generateFilterAllQuery(filter), undefined, true)()
// );

// /**
//  * Get all varieties.
//  */
// export const getAllVarietiesCascara = createAction(
//   "GET_ALL_VARIETIES_CASCARA",
//   () => apiGet(urlItemsVarietiesCascaraSecured, undefined, true)()
// );

// /**
//  * Get all varieties.
//  */
// export const getAllVarieties = createAction("GET_ALL_VARIETIES", () =>
//   apiGet(urlItemsVarietiesSecured, undefined, true)()
// );

// /**
//  * Filter calibres.
//  */
// export const filterCalibresVariety = createAction(
//   "FILTER_ALL_CALIBRES",
//   (filter) =>
//     apiGet(
//       urlItemsCalibresSecured + generateFilterAllQuery(filter),
//       undefined,
//       true
//     )()
// );

// /**
//  * Filter list orders.
//  */
// export const listItemsToExport = createAction(
//   "EXPORT_FILTER_ITEMS",
//   (filter, param) =>
//     apiGet(urlExportItemsSecured + generateFilterQuery(filter), param, true)()
// );
