import React, { useState } from "react";
import * as CompanyServices from "../../../../services/CompanyService";
import PaginateList from "../../../utils/PaginateList";
import { useTranslation } from "react-i18next";
import { ButtonAction, Icon } from "./companyList.styled";
import { useNavigate } from "react-router-dom";

const CompanyListContainer = ({ filters, sortBy, errors }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [forceRefresh, setForceRefresh] = useState(false);

  const columns = [
    {
      header: t("forms.company.labels.name"),
      accessorKey: "name",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.company.labels.cif"),
      accessorKey: "cif",
      cell: (info) => info.getValue(),
    },
    {
      header: "",
      accessorKey: "ac",
      size: 50,
      cell: (info) => renderAction(info.row.original),
    },
  ];

  const renderAction = (data) => {
    return (
      <div className="text-center">
        <ButtonAction
          onClick={() => goToEdit(data.uuid)}
          title={t("forms.company.labels.edit")}
        >
          <Icon className="fa-regular fa-pen-to-square"></Icon>
        </ButtonAction>
      </div>
    );
  };

  const goToEdit = (uuid) => {
    navigate(`/adm/company/update/${uuid}`);
  };

  const listData = async (filter) => {
    if (sortBy) {
      filter.sort = sortBy;
    } else {
      filter.sort = "-createdAt";
    }
    const result = await CompanyServices.listFilter(filter);
    return result;
  };

  const hideColumn = {};
  let resize = null;

  // if (deviceSize < SIZES.md) {
  //   hideColumn.itemCode = false;
  //   resize = false;
  // } else if (deviceSize === SIZES.md) {
  //   hideColumn.itemCode = false;
  // }

  return (
    <>
      <PaginateList
        refresh={forceRefresh}
        filters={filters}
        columns={columns}
        toList={listData}
        hideColum={hideColumn}
        resize={resize}
        resetRefresh={() => setForceRefresh(false)}
      ></PaginateList>
    </>
  );
};

export default CompanyListContainer;
