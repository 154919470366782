import * as Yup from "yup";

export const dataModel = {
  name: "",
  userName: "",
  password: "",
  passwordConfirm: "",
  // companiesUuids: "",
};

export const validationDataModel = (edit) => {
  if (edit) {
    return Yup.object().shape({
      name: Yup.string().required("forms.validations.required"),
      userName: Yup.string().required("forms.validations.required"),
      password: Yup.string()
        .optional("forms.validations.required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s)(?=.*[^\da-zA-Z]).{6,15}$/,
          "forms.validations.password"
        ),
      passwordConfirm: Yup.string().when("password", {
        is: (value) => value && value.length > 0,
        then: () =>
          Yup.string()
            .required("forms.validations.required")
            .oneOf([Yup.ref("password")], "forms.validations.matchPassword"),
        otherwise: () => Yup.string().optional("forms.validations.required"),
      }),
      // companiesUuids: Yup.string().required("forms.validations.required"),
    });
  } else {
    return Yup.object().shape({
      name: Yup.string().required("forms.validations.required"),
      userName: Yup.string().required("forms.validations.required"),
      password: Yup.string()
        .required("forms.validations.required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s)(?=.*[^\da-zA-Z]).{6,15}$/,
          "forms.validations.password"
        ),
      passwordConfirm: Yup.string()
        .required("forms.validations.required")
        .oneOf([Yup.ref("password")], "forms.validations.matchPassword"),
      // companiesUuids: Yup.string().required("forms.validations.required"),
    });
  }
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
