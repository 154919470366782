import { store } from "../store/index";
import {
  filterCompanyAdm,
  createCompany,
  get,
  update,
  listAll,
} from "../store/actions/CompanyActions";

/**
 * List items
 */
export const listFilter = async (filter) => {
  const result = await store.dispatch(filterCompanyAdm(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List All
 */
export const listAllCompany = async () => {
  const result = await store.dispatch(listAll());
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

export const register = async (values) => {
  const result = await store.dispatch(createCompany(values));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const updateCompany = async (values, uuid) => {
  const result = await store.dispatch(update(values, [uuid]));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

/**
 * Get company
 */
export const getCompany = async (uuid) => {
  const result = await store.dispatch(get([uuid]));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
