import React, { useState } from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { useTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./providerForm.data.form";
import InputText from "../../../utils/InputText";
// import InputSelect from "../../../utils/InputSelect";
import Breadcrumb from "../../../utils/breadCrumbs";
import PATHS from "../../../../constants/paths";
import { useNavigate } from "react-router-dom";
import {
  SelectContainer,
  SelectContainer2,
  RowSelect,
  Button,
  Container,
  SubTitle,
  ButtonSelect,
} from "./providerForm.styled";

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      style={{ width: "100%" }}
    />
  );
}

const ProviderForm = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    handleSubmit,
    edit,
    companies,
    companiesSelected,
    onSelect,
    onDelete,
    onSelectAll,
    onDeleteAll,
    filterCompany,
    filter,
    companiesBack,
  } = props;

  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.provider.title"), path: PATHS.LIST_PROVIDER },
    { label: t(`forms.provider.${edit ? "edit" : "new"}`) },
  ];

  const cancelAction = () => {
    navigate(PATHS.LIST_PROVIDER);
  };

  const renderCompaniesAllowed = () => {
    if (companiesBack && companiesBack.length > 0 && filter.length > 0) {
      return companiesBack.map((company) => {
        return (
          <div className="row  no-margin" key={`allow_${company.cif}`}>
            <div className="col-12">
              <RowSelect>
                <div>{`${company.cif} - ${company.name}`}</div>
                <Button type="button" onClick={() => onSelect(company)}>
                  <i className="fa-solid fa-square-plus"></i>
                </Button>
              </RowSelect>
            </div>
          </div>
        );
      });
    } else {
      return companies.map((company) => {
        return (
          <div className="row  no-margin" key={`allow_${company.cif}`}>
            <div className="col-12">
              <RowSelect>
                <div>{`${company.cif} - ${company.name}`}</div>
                <Button type="button" onClick={() => onSelect(company)}>
                  <i className="fa-solid fa-square-plus"></i>
                </Button>
              </RowSelect>
            </div>
          </div>
        );
      });
    }
  };

  const renderCompaniesSelected = () => {
    return companiesSelected.map((company) => {
      return (
        <div className="row no-margin" key={`selected_${company.cif}`}>
          <div className="col-12">
            <RowSelect>
              <div>{`${company.cif} - ${company.name}`}</div>
              <Button type="button" onClick={() => onDelete(company)}>
                <i className="fa-solid fa-square-minus"></i>
              </Button>
            </RowSelect>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="row justify-content-center mt-4 mt-md-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">
            {t(`forms.provider.${edit ? "edit" : "new"}`)}
          </h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center  ">
        <div className="col-12 col-md-11">
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="row">
              <div className="col-5">
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="name"
                      className="form-control"
                      labelField={t("forms.provider.labels.name")}
                      name="name"
                      component={InputText}
                      fieldType="text"
                      placeholder=""
                      mandatory
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-2">
                    <Field
                      id="userName"
                      className="form-control"
                      labelField={t("forms.provider.labels.userName")}
                      name="userName"
                      component={InputText}
                      fieldType="text"
                      placeholder=""
                      mandatory
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-2">
                    <Field
                      id="password"
                      className="form-control"
                      labelField={t("forms.provider.labels.password")}
                      name="password"
                      component={InputText}
                      fieldType="password"
                      showHidePassword
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="passwordConfirm"
                      className="form-control"
                      labelField={t("forms.provider.labels.passwordConfirm")}
                      name="passwordConfirm"
                      component={InputText}
                      fieldType="password"
                      showHidePassword
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-7">
                <div className="row mb-3">
                  <div className="col-12">
                    <SubTitle>{t("forms.provider.selectCompany")}</SubTitle>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <SelectContainer>
                      <SelectContainer2>
                        <DebouncedInput
                          className=""
                          onChange={(value) => filterCompany(value)}
                          placeholder={`Search...`}
                          type="text"
                          value={filter ?? ""}
                        />
                        {companies && renderCompaniesAllowed()}
                      </SelectContainer2>
                    </SelectContainer>
                  </div>
                  <div className="col-6">
                    <SelectContainer>
                      <SelectContainer2>
                        {companiesSelected && renderCompaniesSelected()}
                      </SelectContainer2>
                    </SelectContainer>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6 text-end pe-0">
                    <ButtonSelect
                      type="button"
                      onClick={() => onSelectAll()}
                      className="me-2"
                    >
                      <i className="fa-regular fa-square-plus me-1"></i>
                      {t("forms.provider.buttons.selectAll")}
                    </ButtonSelect>
                  </div>
                  <div className="col-6 text-end pe-0">
                    <ButtonSelect
                      type="button"
                      onClick={() => onDeleteAll()}
                      className="me-2"
                    >
                      <i className="fa-regular fa-trash-can me-1"></i>
                      {t("forms.provider.buttons.deleteAll")}
                    </ButtonSelect>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-10 col-sm-11 text-center mt-5 mb-5">
                <button
                  type="button"
                  onClick={() => cancelAction()}
                  className="btn btn-md btn-secondary me-2"
                >
                  {t("forms.provider.buttons.cancel")}
                </button>
                <button type="submit" className="btn btn-md btn-primary">
                  {t("forms.provider.buttons.save")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

ProviderForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

const ProviderFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "ProviderForm",
})(ProviderForm);

export default ProviderFormF;
