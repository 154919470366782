import { createAction } from "redux-actions";
import { apiGet, apiPost, apiPut } from "../../api/index";
import { urlCompanySecured, urlCompanyAllSecured } from "../../api/urls";
import { PAGE_SIZE_10 } from "../../constants/index";

const generateFilterQuery = ({ filters, page, pageSize, sort }) => {
  let filter = "?";

  page ? (filter += "page=" + page) : (filter += "page=1");
  pageSize
    ? (filter += "&pageSize=" + pageSize)
    : (filter += "&pageSize=" + PAGE_SIZE_10);

  if (filters) {
    if (filters.cif !== undefined) filter += "&cif=" + filters.cif;
    if (filters.name !== undefined) filter += "&name=" + filters.name;
  }
  if (sort) filter += "&sort=" + sort;
  return filter;
};

/**
 * Filter list company.
 */
export const filterCompanyAdm = createAction(
  "FILTER_COMPANY",
  (filter, param) =>
    apiGet(urlCompanySecured + generateFilterQuery(filter), param, true)()
);

/**
 * Filter list company.
 */
export const listAll = createAction("LIST_ALL_COMPANY", () =>
  apiGet(urlCompanyAllSecured, undefined, true)()
);

/**
 * Filter list company.
 */
export const get = createAction("GET_COMPANY", (param) =>
  apiGet(urlCompanySecured, param, true)()
);

/**
 * Create company.
 */
export const createCompany = createAction("REGISTER_COMPANY", (obj) =>
  apiPost(urlCompanySecured, obj, undefined, true)()
);

/**
 * Update company.
 */
export const update = createAction("UPDATE_COMPANY", (obj, param) =>
  apiPut(urlCompanySecured, obj, param, true)()
);
