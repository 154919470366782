import React, { useState } from "react";
import List from "../uploadList";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PATHS from "../../../../constants/paths";
import Breadcrumb from "../../../utils/breadCrumbs";

const queryClient = new QueryClient();

const UploadFilterListContainer = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.upload.title") },
  ];
  const [filters, setFilters] = useState(undefined);

  const goToForm = async () => {
    navigate(PATHS.NEW_UPLOAD);
  };

  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.upload.title")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <QueryClientProvider client={queryClient}>
            <List className="marg-top-1rem" filters={filters} />
          </QueryClientProvider>
        </div>
      </div>
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-11 d-flex justify-content-center">
          <button
            className="btn btn-secondary btn-lg"
            onClick={() => goToForm()}
          >
            {t("forms.upload.buttons.new")}
          </button>
        </div>
      </div>
    </>
  );
};

UploadFilterListContainer.propTypes = {};

export default UploadFilterListContainer;
