import { createAction } from "redux-actions";
import { apiGet } from "../../api/index";
import { urlProductionDataSecured } from "../../api/urls";
import { PAGE_SIZE_10 } from "../../constants/index";

const generateFilterQuery = ({ filters, page, pageSize, sort }) => {
  let filter = "?";

  page ? (filter += "page=" + page) : (filter += "page=1");
  pageSize
    ? (filter += "&pageSize=" + pageSize)
    : (filter += "&pageSize=" + PAGE_SIZE_10);

  if (filters) {
    if (filters.type !== undefined) filter += "&type=" + filters.type;
  }
  if (sort) filter += "&sort=" + sort;
  return filter;
};

/**
 * Filter list data.
 */
export const filterData = createAction("FILTER_DATA", (filter, param) =>
  apiGet(urlProductionDataSecured + generateFilterQuery(filter), param, true)()
);
