import * as Yup from "yup";

export const dataModel = {
  name: "",
  userName: "",
};

export const validationDataModel = Yup.object().shape({
  name: Yup.string().optional(""),
  userName: Yup.string().optional(""),
});

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
