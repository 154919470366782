import { store } from "../store/index";
import {
  filterProviderAdm,
  createProvider,
  get,
  update,
} from "../store/actions/ProviderActions";

/**
 * List items
 */
export const listFilter = async (filter) => {
  const result = await store.dispatch(filterProviderAdm(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

export const register = async (values, companiesSelected) => {
  delete values.passwordConfirm;
  if (companiesSelected && companiesSelected.length > 0) {
    const list = [];
    for (const com of companiesSelected) {
      list.push(com.uuid);
    }
    values.companiesUuids = list;
  }
  const result = await store.dispatch(createProvider(values));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const updateProvider = async (values, companiesSelected, uuid) => {
  delete values.passwordConfirm;
  if (!values.password) {
    delete values.password;
  }
  if (companiesSelected && companiesSelected.length > 0) {
    const list = [];
    for (const com of companiesSelected) {
      list.push(com.uuid);
    }
    values.companiesUuids = list;
  }
  const result = await store.dispatch(update(values, [uuid]));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

/**
 * Get provider
 */
export const getProvider = async (uuid) => {
  const result = await store.dispatch(get([uuid]));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
