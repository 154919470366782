import { createAction } from "redux-actions";
import { apiGet, apiPostFile } from "../../api/index";
import { urlUploadSecured } from "../../api/urls";
import { PAGE_SIZE_10 } from "../../constants/index";

const generateFilterQuery = ({ filters, page, pageSize, sort }) => {
  let filter = "?";

  page ? (filter += "page=" + page) : (filter += "page=1");
  pageSize
    ? (filter += "&pageSize=" + pageSize)
    : (filter += "&pageSize=" + PAGE_SIZE_10);

  if (filters) {
    // if (filters.code !== undefined) filter += "&code=" + filters.code;
  }
  if (sort) filter += "&sort=" + sort;
  return filter;
};

/**
 * Filter list upload.
 */
export const filterUploadAdm = createAction("FILTER_UPLOAD", (filter, param) =>
  apiGet(urlUploadSecured + generateFilterQuery(filter), param, true)()
);

/**
 * Create upload.
 */
export const createUpload = createAction("REGISTER_UPLOAD", (formData) =>
  apiPostFile(urlUploadSecured, formData, undefined, true)()
);
