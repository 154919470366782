import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ButtonType } from "./data.styled";
import { TYPE_ALMOND, TYPE_ENTAMADO, TYPE_LAGAR } from "../../../constants";

const DetailDataView = (props) => {
  const { t } = useTranslation();

  const { onSelectType, typeSelected } = props;

  return (
    <div className="row justify-content-center mt-5 mb-5">
      <div className="col-12 d-flex justify-content-end">
        <ButtonType
          type="button"
          $isSelected={typeSelected === TYPE_ALMOND.code}
          onClick={() => onSelectType(TYPE_ALMOND.code)}
        >
          Almendra
        </ButtonType>
        <ButtonType
          type="button"
          $isSelected={typeSelected === TYPE_ENTAMADO.code}
          onClick={() => onSelectType(TYPE_ENTAMADO.code)}
        >
          Entamadora
        </ButtonType>
        <ButtonType
          type="button"
          $isSelected={typeSelected === TYPE_LAGAR.code}
          onClick={() => onSelectType(TYPE_LAGAR.code)}
        >
          Lagar
        </ButtonType>
      </div>
    </div>
  );
};

DetailDataView.propTypes = {
  data: PropTypes.any,
};

export default DetailDataView;
