import styled from "styled-components";

export const ConfirmFile = styled.span`
  color: green;
  font-size: 1rem;
  font-weight: bold;
`;

export const ListError = styled.ul`
  margin-bottom: 0px !important;
`;
