import { Navigate, Routes, Route } from "react-router-dom";
import AuthConfirmPage from "./pages/authConfirm";
import LoginPage from "./pages/login";
import ExtLoginPage from "./pages/extLogin";
import ProtectedProviderRoutes from "./routes/ProtectedProviderRoutes";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import PATHS from "./constants/paths";
import DetailDataPage from "./pages/data";
import ProviderListDataPage from "./pages/provider/list";
import NewProviderPage from "./pages/provider/form";
import CompanyListDataPage from "./pages/company/list";
import NewCompanyPage from "./pages/company/form";
import UploadListDataPage from "./pages/upload/list";
import NewUploadPage from "./pages/upload/form";
import ChangePasswordPage from "./pages/changePass";

function App() {
  return (
    <Routes>
      {/* <Route path="/logout" element={<Logout />} /> */}
      <Route path="/user" element={<ProtectedProviderRoutes />}>
        <Route path={PATHS.SHOW_DATA} exact element={<DetailDataPage />} />
        <Route
          path={PATHS.CHNAGE_PASSWORD}
          exact
          element={<ChangePasswordPage />}
        />
      </Route>

      <Route path="/adm" element={<ProtectedRoutes />}>
        <Route
          path={PATHS.LIST_PROVIDER}
          exact
          element={<ProviderListDataPage />}
        />
        <Route path={PATHS.NEW_PROVIDER} exact element={<NewProviderPage />} />
        <Route
          path={PATHS.UPDATE_PROVIDER}
          exact
          element={<NewProviderPage />}
        />

        <Route
          path={PATHS.LIST_COMPANY}
          exact
          element={<CompanyListDataPage />}
        />
        <Route path={PATHS.NEW_COMPANY} exact element={<NewCompanyPage />} />
        <Route path={PATHS.UPDATE_COMPANY} exact element={<NewCompanyPage />} />

        <Route
          path={PATHS.LIST_UPLOAD}
          exact
          element={<UploadListDataPage />}
        />
        <Route path={PATHS.NEW_UPLOAD} exact element={<NewUploadPage />} />
      </Route>

      <Route
        path={PATHS.EXTERNAL_CONFIRM_LOGIN}
        exact
        element={<AuthConfirmPage />}
      />
      <Route path="/ext/login" exact element={<ExtLoginPage />} />
      <Route path="/login" exact element={<LoginPage />} />
      <Route path="/" element={<Navigate to={"/ext/login"} />} />
    </Routes>
  );
}

export default App;
