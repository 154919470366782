import React from "react";
import ProviderList from "../../../components/adm/provider/providerFilterList";
import Layout from "../../../components/layouts/verticalLayout";

const DataPage = () => {
  return (
    <Layout
      body={
        <>
          <ProviderList />
        </>
      }
    />
  );
};

DataPage.propTypes = {};

export default DataPage;
