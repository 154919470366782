import React, { useState, useMemo, useEffect } from "react";
import * as DataUploadService from "../../../services/DataUploadService";
import PaginateFilterList from "../../utils/PaginateFilterList";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import moment from "moment-timezone";
import { TYPE_ENTAMADO } from "../../../constants";
import { roundAndFix, formatearNumero } from "../../../utils/formats";
import { ResumeTitle, ResumeCell } from "./entamadoraList.styled";

const AlmondListContainer = ({ filters, sortBy, errors }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const columns = useMemo(
    () => [
      {
        header: t("data.ticket"),
        accessorKey: "numTicket",
        cell: (info) => info.getValue(),
        size: 100,
      },
      {
        header: t("data.company"),
        accessorKey: "company.name",
        cell: (info) => {
          if (info.row.original.company) {
            return info.row.original.company.name;
          }

          return "";
        },
      },
      {
        header: t("data.talao"),
        accessorKey: "talao",
        cell: (info) => info.getValue(),
        size: 100,
      },
      {
        header: t("data.farm"),
        accessorKey: "farm",
        cell: (info) => info.getValue(),
      },
      {
        header: t("data.date"),
        accessorKey: "date",
        cell: (info) => info.getValue(),
        size: 100,
      },
      {
        header: t("data.kg"),
        accessorKey: "inWeight",
        cell: (info) => Math.round(info.getValue()),
      },
      {
        header: t("data.variety"),
        accessorKey: "variety",
        cell: (info) => info.getValue(),
      },
      // {
      //   header: t("data.kgRehuz"),
      //   accessorKey: "kgRehuz",
      //   cell: (info) => Math.round(info.getValue()),
      //   size: 100,
      // },
      {
        header: t("data.rehuzPercent"),
        accessorKey: "rehuzPercent",
        cell: (info) => roundAndFix(info.getValue(), 2),
        size: 100,
      },
      // {
      //   header: t("data.kgMorada"),
      //   accessorKey: "kgMorada",
      //   cell: (info) => Math.round(info.getValue()),
      //   size: 80,
      // },
      {
        header: t("data.moradaPercent"),
        accessorKey: "moradaPercent",
        cell: (info) => roundAndFix(info.getValue(), 2),
        size: 80,
      },
      // {
      //   header: t("data.kgPerdigon"),
      //   accessorKey: "kgPerdigon",
      //   cell: (info) => Math.round(info.getValue()),
      //   size: 100,
      // },
      {
        header: t("data.perdigonPercent"),
        accessorKey: "perdigonPercent",
        cell: (info) => roundAndFix(info.getValue(), 2),
        size: 100,
      },
      // {
      //   header: t("data.kgEntama"),
      //   accessorKey: "kgEntama",
      //   cell: (info) => Math.round(info.getValue()),
      // },
      {
        header: t("data.entamaPercent"),
        accessorKey: "entamaPercent",
        cell: (info) => roundAndFix(info.getValue(), 2),
      },
      // {
      //   header: t("data.kgMolestado"),
      //   accessorKey: "kgMolestado",
      //   cell: (info) => Math.round(info.getValue()),
      // },
      {
        header: t("data.molestadoPercent"),
        accessorKey: "molestadoPercent",
        cell: (info) => roundAndFix(info.getValue(), 2),
      },
      {
        header: t("data.calibre"),
        accessorKey: "calibre",
        cell: (info) => info.getValue(),
      },
      // {
      //   header: t("data.userData"),
      //   accessorKey: "userData",
      //   cell: (info) => info.getValue(),
      // },
    ],
    []
  );

  const exportToExcel = async () => {
    const dataExp = data.map((item) => {
      // const type = ALMOND_TYPE.find((st) => st.code === item.type);
      return {
        A: item.numTicket,
        B: item?.company?.name || "",
        C: item.talao,
        D: item.farm,
        E: item.date,
        F: item.inWeight,
        G: item.variety,
        H: item.rehuzPercent,
        I: item.moradaPercent,
        J: item.perdigonPercent,
        K: item.entamaPercent,
        L: item.molestadoPercent,
        M: item.calibre,
      };
    });

    dataExp.unshift({
      A: t("data.ticket"),
      B: t("data.company"),
      C: t("data.talao"),
      D: t("data.farm"),
      E: t("data.date"),
      F: t("data.kg"),
      G: t("data.variety"),
      H: t("data.rehuzPercent"),
      I: t("data.moradaPercent"),
      J: t("data.perdigonPercent"),
      K: t("data.entamaPercent"),
      L: t("data.molestadoPercent"),
      M: t("data.calibre"),
    });

    const filename = `Entamado_export_${moment(new Date()).format(
      "DDMMyyyyHHmm"
    )}`;
    const sheetName = "Listado de Artículos";
    var ws = XLSX.utils.json_to_sheet(dataExp, {
      header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M"],
      skipHeader: true,
      UTC: true,
      dateNF: "dd-mm-yyyy hh:mm;@",
    });
    // dateNF:'mm/dd/yyyy;@',cellDates:true, raw: true}
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, filename + ".xlsx");
  };

  const listData = async () => {
    const filter = {
      page: 1,
      pageSize: 10,
      sort: "-createdAt",
      filters: {
        type: TYPE_ENTAMADO.code,
      },
    };
    const result = await DataUploadService.listFilter(filter);
    setData(result);
    return result;
  };

  useEffect(() => {
    listData();
  }, []);

  const renderCustomResume = (rows) => {
    let totalKgEntregues = 0;
    let totalKgEntama = 0;
    let totalKgMolestado = 0;
    let totalKgMolino = 0;
    let totalRehuz = 0;
    let totalMorada = 0;
    let totalPerdigon = 0;
    let calibreMedio = 0;
    for (let index = 0; index < rows.length; index++) {
      const element = rows[index].original;
      totalKgEntregues += element.inWeight;
      totalKgEntama += element.kgEntama;
      totalKgMolestado += element.kgMolestado;
      totalRehuz += element.kgRehuz;
      totalMorada += element.kgMorada;
      totalPerdigon += element.kgPerdigon;
      calibreMedio += element.calibre;
    }

    totalKgMolino = totalRehuz + totalMorada + totalPerdigon;

    return (
      <>
        <div className="row no-margin">
          <div className="col-6 mb-3">
            <div className="row ">
              <ResumeTitle $first className="col-4 text-center">
                {t("totals")}
              </ResumeTitle>
              <ResumeTitle className="col-4 text-center">
                {t("data.kg")}
              </ResumeTitle>
              <ResumeTitle className="col-4 text-center">%</ResumeTitle>
            </div>
            <div className="row">
              <ResumeCell $first className="col-4 text-center">
                {t("totalsEntr")}:
              </ResumeCell>
              <ResumeCell className="col-4 text-center">
                {formatearNumero(roundAndFix(totalKgEntregues, 2))} Kg
              </ResumeCell>
              <ResumeCell className="col-4 text-center">100%</ResumeCell>
            </div>
            <div className="row">
              <ResumeCell $first className="col-4 text-center">
                {t("totalsEnta")}:
              </ResumeCell>
              <ResumeCell className="col-4 text-center">
                {formatearNumero(roundAndFix(totalKgEntama, 2))} Kg
              </ResumeCell>
              <ResumeCell className="col-4 text-center">
                {roundAndFix((totalKgEntama / totalKgEntregues) * 100, 2)}%
              </ResumeCell>
            </div>
            <div className="row">
              <ResumeCell $first className="col-4 text-center">
                {t("totalsMol")}:
              </ResumeCell>
              <ResumeCell className="col-4 text-center">
                {formatearNumero(roundAndFix(totalKgMolestado, 2))} Kg
              </ResumeCell>
              <ResumeCell className="col-4 text-center">
                {roundAndFix((totalKgMolestado / totalKgEntama) * 100, 2)}%
              </ResumeCell>
            </div>
            <div className="row">
              <ResumeCell $first className="col-4 text-center">
                {t("totalsMoli")}:
              </ResumeCell>
              <ResumeCell className="col-4 text-center">
                {formatearNumero(roundAndFix(totalKgMolino, 1))} Kg
              </ResumeCell>
              <ResumeCell className="col-4 text-center">
                {roundAndFix((totalKgMolino / totalKgEntregues) * 100, 2)}%
              </ResumeCell>
            </div>
            <div className="row">
              <ResumeCell $first className="col-4 text-center">
                {t("totalsRe")}:
              </ResumeCell>
              <ResumeCell className="col-4 text-center">
                {formatearNumero(roundAndFix(totalRehuz, 1))} Kg
              </ResumeCell>
              <ResumeCell className="col-4 text-center">
                {roundAndFix((totalRehuz / totalKgEntregues) * 100, 2)}%
              </ResumeCell>
            </div>
            <div className="row">
              <ResumeCell $first className="col-4 text-center">
                {t("totalsMod")}:
              </ResumeCell>
              <ResumeCell className="col-4 text-center">
                {formatearNumero(roundAndFix(totalMorada, 1))} Kg
              </ResumeCell>
              <ResumeCell className="col-4 text-center">
                {roundAndFix((totalMorada / totalKgEntregues) * 100, 2)}%
              </ResumeCell>
            </div>
            <div className="row">
              <ResumeCell $first className="col-4 text-center">
                {t("totalsPer")}:
              </ResumeCell>
              <ResumeCell className="col-4 text-center">
                {formatearNumero(roundAndFix(totalPerdigon, 1))} Kg
              </ResumeCell>
              <ResumeCell className="col-4 text-center">
                {roundAndFix((totalPerdigon / totalKgEntregues) * 100, 2)}%
              </ResumeCell>
            </div>
            <div className="row">
              <ResumeCell $first className="col-4 text-center">
                {t("totalsCal")}:
              </ResumeCell>
              <ResumeCell className="col-4 text-center">
                {isNaN(Math.round(calibreMedio / rows.length))
                  ? 0
                  : Math.round(calibreMedio / rows.length)}
              </ResumeCell>
              <ResumeCell className="col-4 text-center"></ResumeCell>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="mb-3">
        <PaginateFilterList
          data={data}
          columns={columns}
          customResume={renderCustomResume}
        ></PaginateFilterList>
      </div>
      <div className="row justify-content-center mb-5">
        <div className="col-12 text-center">
          <button
            type="button"
            className="btn btn-md btn-secondary"
            onClick={() => exportToExcel()}
          >
            <i className="fa-solid fa-file-excel icon-button"></i>{" "}
            {t("exportFileExcel")}
          </button>
        </div>
      </div>
    </>
  );
};

export default AlmondListContainer;
