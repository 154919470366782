export const PAGE_SIZE_10 = 10;
export const CLEAN_AUTH = "CLEAN_AUTH";
export const SET_AUTH_USER = "SET_AUTH_USER";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const GET_PROFILE = "GET_PROFILE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const CLEAN_NAV = "CLEAN_NAV";

export const ROLE_ADMIN = "admin";
export const ROLE_STAFF = "staff";

export const LANGUAGE_ES = {
  code: "es",
  label: "language.languages.es",
};
export const LANGUAGE_PT = {
  code: "pt",
  label: "language.languages.pt",
};

export const LANGUAGES = [LANGUAGE_ES, LANGUAGE_PT];

export const TYPE_ALMOND = {
  code: 1,
  label: "type.almond",
};
export const TYPE_ENTAMADO = {
  code: 2,
  label: "type.entamado",
};
export const TYPE_LAGAR = {
  code: 3,
  label: "type.lagar",
};
export const UPLOAD_TYPE = [TYPE_ALMOND, TYPE_ENTAMADO, TYPE_LAGAR];

export const STATUS_IN_PROCESS = {
  code: "IN PROCESS",
  label: "status.inProcess",
};
export const STATUS_COMPLETE = {
  code: "COMPLETE",
  label: "status.complete",
};
export const STATUS_ERROR = {
  code: "ERROR",
  label: "status.error",
};
export const UPLOAD_STATUS = [STATUS_IN_PROCESS, STATUS_COMPLETE, STATUS_ERROR];
