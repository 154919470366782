import React from "react";
import CompanyList from "../../../components/adm/company/companyFilterList";
import Layout from "../../../components/layouts/verticalLayout";

const CompanyListPage = () => {
  return (
    <Layout
      body={
        <>
          <CompanyList />
        </>
      }
    />
  );
};

CompanyListPage.propTypes = {};

export default CompanyListPage;
