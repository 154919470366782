import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAuthUserSelector } from "../store/selectors/authSelector";

const useAuth = () => {
  let auth = false;
  const user = useSelector(getAuthUserSelector);
  if (user && user.roles && user.roles.includes("manager")) {
    auth = true;
  }
  return auth;
};

const ProtectedRoutes = (props) => {
  const auth = useAuth();

  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
