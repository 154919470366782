/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import Axios from "axios";
import promise from "promise";
import { show, hide } from "redux-modal";
// import { handleErrorResponse } from "../utils/errors/ErrorControl";
import { store } from "../store";

const showLoading = () => {
  const currentState = store.getState();
  if (
    !currentState.modal.LoadingModal ||
    (currentState.modal.LoadingModal && !currentState.modal.LoadingModal.show)
  ) {
    store.dispatch(show("LoadingModal", {}));
  }
};

const hideLoading = () => {
  const currentState = store.getState();
  if (
    currentState.modal.LoadingModal ||
    (currentState.modal.LoadingModal &&
      currentState.modal.LoadingModal.show === true)
  ) {
    store.dispatch(hide("LoadingModal", {}));
  }
};

// Add a request interceptor
export const axios = Axios.create({
  withCredentials: true,
});

axios.interceptors.request.use(
  async (config) => {
    if (config.headers.loading) {
      showLoading();
    }
    const storeState = store.getState();
    if (
      storeState.auth &&
      storeState.auth.dataToken &&
      storeState.auth.dataToken.access_token
    ) {
      const { access_token } = storeState.auth.dataToken;
      if (access_token) {
        config.headers.authorization = "Bearer " + access_token;
      }
    }

    return config;
  },
  (error) => {
    // Do something with request error
    // hideLoading();
    return promise.reject(error);
  }
);

axios.interceptors.response.use(
  async (config) => {
    hideLoading();
    return config;
  },
  (error) => {
    // Do something with request error
    hideLoading();
    // handleErrorResponse(error);
    return promise.reject(error);
  }
);

// use request with credentials, auto save login token into browser
// axiosInstanceNormal.defaults.withCredentials = true;
