import React from "react";
import DetailData from "../../components/user/data";
import Layout from "../../components/layouts/verticalLayout";

const DataPage = () => {
  return (
    <Layout
      body={
        <>
          <DetailData />
        </>
      }
    />
  );
};

DataPage.propTypes = {};

export default DataPage;
