import { createAction } from "redux-actions";
import {
  CLEAN_AUTH,
  SET_AUTH_TOKEN,
  SET_AUTH_USER,
  GET_PROFILE,
} from "../../constants/index";
import { apiGet, apiPost, apiPut } from "../../api/index";
import {
  urlLogout,
  urlGetProfile,
  urlProviderLogin,
  urlAuthChangePass,
} from "../../api/urls";

export const logoutUser = createAction(CLEAN_AUTH, (data) => data);

export const setAuthUser = createAction(SET_AUTH_USER, (data) => data);
export const setAuthToken = createAction(SET_AUTH_TOKEN, (data) => data);

/**
 * User Refresh token.
 */
export const getProfile = createAction(GET_PROFILE, () =>
  apiGet(urlGetProfile, undefined, undefined)()
);

/**
 * User Refresh token.
 */
export const userLogout = createAction(CLEAN_AUTH, (obj) =>
  apiGet(urlLogout, undefined, undefined)()
);

/**
 * Create company.
 */
export const loginExt = createAction("LOGIN_EXT", (obj) =>
  apiPost(urlProviderLogin, obj, undefined, true)()
);

/**
 * Get auth info profile (user loged).
 */
export const updatePassword = createAction("CHANGE_PASSWORD", (obj) =>
  apiPut(urlAuthChangePass, obj, undefined, true)()
);
