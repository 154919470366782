import { store } from "../store/index";
import { setLanguage, getLoginItem } from "../store/actions/GeneralActions";
import { setAuthUser, setAuthToken } from "../store/actions/AuthActions";

/**
 * Set setPreferredLanguage language
 */
export const setPreferredLanguage = async (obj) => {
  store.dispatch(setLanguage(obj));
};

/**
 * Get item
 */
export const getItemLoginTemp = async (token) => {
  let result = await store.dispatch(getLoginItem(token));
  if (result?.payload?.access_token) {
    await store.dispatch(setAuthUser(result.payload.user));
    await store.dispatch(
      setAuthToken({
        access_token: result.payload.access_token,
        token_expiration: result.payload.token_expiration,
      })
    );
    return "OK";
  }
  return null;
};
