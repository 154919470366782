import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
// import InputSelect from "../../../utils/InputSelect";
import Breadcrumb from "../../../utils/breadCrumbs";
import PATHS from "../../../../constants/paths";
import { useNavigate } from "react-router-dom";
import { ConfirmFile, ListError } from "./uploadForm.styled";
import { useDropzone } from "react-dropzone";
import Select from "react-select";
import { UPLOAD_TYPE } from "../../../../constants/index";

const UploadForm = (props) => {
  const { t } = useTranslation();
  const { onSubmit, onSelectType } = props;
  const navigate = useNavigate();
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      maxFiles: 1,
      accept: {
        "application/vnd.ms-excel": [".xlx"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlxs",
        ],
      },
    });

  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.upload.title"), path: PATHS.LIST_COMPANY },
    { label: t("forms.upload.new") },
  ];

  const types = [];
  for (const type of UPLOAD_TYPE) {
    types.push({
      value: type.code,
      label: t(type.label),
    });
  }

  const cancelAction = () => {
    navigate(PATHS.LIST_COMPANY);
  };

  const send = () => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      onSubmit(acceptedFiles[0]);
    }
  };

  const renderFile = () => {
    const file = acceptedFiles[0];
    return (
      <ConfirmFile>
        {file.path} - {file.size} bytes
      </ConfirmFile>
    );
  };

  const renderErrorFile = () => {
    const { file, errors } = fileRejections[0];
    return (
      <div className="alert alert-danger mt-3" role="alert">
        <ListError>
          {errors.map((e) => (
            <li key={e.code}>
              {`${file.path} (${file.size} bytes)`} -{" "}
              {t(`file.error.${e.code}`)}
            </li>
          ))}
        </ListError>
      </div>
    );
  };

  return (
    <>
      <div className="row justify-content-center mt-4 mt-md-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.upload.new")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center  ">
        <div className="col-12 col-md-11">
          <div className="row mb-3">
            <div className="col-12">
              <label className="control-label mandatory">
                {t("forms.upload.labels.type")}
              </label>
              <Select
                id="type"
                name="type"
                onChange={(value) => {
                  onSelectType(value);
                }}
                options={types}
                classNamePrefix="dark"
                placeholder=""
              />
            </div>
          </div>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>
              {acceptedFiles && acceptedFiles.length > 0
                ? renderFile()
                : t("forms.upload.labels.file")}
            </p>
          </div>
          {fileRejections && fileRejections.length > 0 ? renderErrorFile() : ""}

          <div className="row justify-content-center">
            <div className="col-10 col-sm-11 text-center mt-5 mb-5">
              <button
                type="button"
                onClick={() => cancelAction()}
                className="btn btn-md btn-secondary me-2"
              >
                {t("forms.upload.buttons.cancel")}
              </button>
              <button
                type="button"
                onClick={() => send()}
                className="btn btn-md btn-primary me-2"
              >
                {t("forms.upload.buttons.save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

UploadForm.propTypes = {
  handleSubmit: PropTypes.func,
};

export default UploadForm;
