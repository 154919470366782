import React, { useState } from "react";
import * as UploadServices from "../../../../services/UploadService";
import PaginateList from "../../../utils/PaginateList";
import { useTranslation } from "react-i18next";
import { ButtonAction, Icon } from "./uploadList.styled";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { UPLOAD_TYPE, UPLOAD_STATUS } from "../../../../constants/index";

const UploadListContainer = ({ filters, sortBy, errors }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [forceRefresh, setForceRefresh] = useState(false);

  const columns = [
    {
      header: t("forms.upload.labels.date"),
      accessorKey: "date",
      cell: (info) => moment(info.getValue()).format("DD-MM-yyyy HH:mm"),
    },
    {
      header: t("forms.upload.labels.user"),
      accessorKey: "userUpload",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.upload.labels.type"),
      accessorKey: "type",
      cell: (info) => {
        if (info.row.original.type) {
          const type = UPLOAD_TYPE.find(
            (st) => st.code === info.row.original.type
          );
          return type ? t(type.label) : info.getValue();
        }
        return "-";
      },
    },
    {
      header: t("forms.upload.labels.status"),
      accessorKey: "status",
      cell: (info) => {
        if (info.row.original.status) {
          const status = UPLOAD_STATUS.find(
            (st) => st.code === info.row.original.status
          );
          return status ? t(status.label) : info.getValue();
        }
        return "-";
      },
    },
  ];

  const listData = async (filter) => {
    if (sortBy) {
      filter.sort = sortBy;
    } else {
      filter.sort = "-createdAt";
    }
    const result = await UploadServices.listFilter(filter);
    return result;
  };

  const hideColumn = {};
  let resize = null;

  // if (deviceSize < SIZES.md) {
  //   hideColumn.itemCode = false;
  //   resize = false;
  // } else if (deviceSize === SIZES.md) {
  //   hideColumn.itemCode = false;
  // }

  return (
    <>
      <PaginateList
        refresh={forceRefresh}
        filters={filters}
        columns={columns}
        toList={listData}
        hideColum={hideColumn}
        resize={resize}
        resetRefresh={() => setForceRefresh(false)}
      ></PaginateList>
    </>
  );
};

export default UploadListContainer;
