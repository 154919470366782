import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Title, Container, SubTitle } from "./login.styled";
import Languages from "../../layouts/commons/header/components/languages";
import { withFormik, Field } from "formik";
import { dataModel, validationDataModel } from "./login.data.form";
import InputText from "../../utils/InputText";

const LoginForm = (props) => {
  const { handleSubmit } = props;
  const { t } = useTranslation();

  return (
    <div className="login-page row no-margin">
      <div className="col-12">
        <div className="row justify-content-center">
          <div className="col-10 mt-2 d-flex justify-content-end">
            <Languages />
          </div>
        </div>
        <Container className="row justify-content-center align-items-center">
          <div className="col-10">
            <div className="row justify-content-center mt-5">
              <div className="col-10 mt-2 d-flex justify-content-center">
                <Title>{t("title")}</Title>
              </div>
            </div>
            <div className="row justify-content-center align-items-center mt-4">
              <div className="col-4">
                <div className="row justify-content-center">
                  <div className="col-12 mt-3 mb-2">
                    <SubTitle>{t("identify")}</SubTitle>
                  </div>
                </div>
                <form
                  className="form-horizontal bordered-row"
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                    <div className="col-12">
                      <Field
                        id="user"
                        className="form-control"
                        name="user"
                        component={InputText}
                        fieldType="text"
                        labelField={t("user")}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <Field
                        id="password"
                        className="form-control"
                        name="password"
                        component={InputText}
                        fieldType="password"
                        showHidePassword
                        labelField={t("password")}
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12 text-center">
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary btn-block"
                      >
                        {t("access")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func,
  gotoLogin: PropTypes.func,
};

const LoginFormF = withFormik({
  mapPropsToValues: () => dataModel,
  validationSchema: validationDataModel,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: "LoginForm",
})(LoginForm);

export default LoginFormF;
