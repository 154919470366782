import React, { useState } from "react";
import * as ProviderServices from "../../../../services/ProviderService";
import PaginateList from "../../../utils/PaginateList";
import { useTranslation } from "react-i18next";
import { ButtonAction, Icon } from "./providerList.styled";
import { useNavigate } from "react-router-dom";

const ProviderListContainer = ({ filters, sortBy, errors }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [forceRefresh, setForceRefresh] = useState(false);

  const columns = [
    {
      header: t("forms.provider.labels.name"),
      accessorKey: "name",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.provider.labels.userName"),
      accessorKey: "userName",
      cell: (info) => info.getValue(),
    },
    {
      header: "",
      accessorKey: "ac",
      size: 50,
      cell: (info) => renderAction(info.row.original),
    },
    // {
    //   header: t("forms.provider.labels.calibre"),
    //   accessorKey: "calibre",
    //   size: 100,
    //   cell: (info) => {
    //     if (info.row.original.calibre === "NO SEL") {
    //       return "-";
    //     }
    //     return info.getValue();
    //   },
    // },
    // {
    //   header: t("forms.provider.labels.type"),
    //   accessorKey: "type",
    //   size: 100,
    //   cell: (info) => {
    //     if (info.row.original.type) {
    //       const type = ALMOND_TYPE.find(
    //         (st) => st.code === info.row.original.type
    //       );
    //       return type ? t(type.label) : info.getValue();
    //     }
    //     return "-";
    //   },
    // },
  ];

  const renderAction = (data) => {
    return (
      <div className="text-center">
        <ButtonAction
          onClick={() => goToEdit(data.uuid)}
          title={t("forms.provider.labels.edit")}
        >
          <Icon className="fa-regular fa-pen-to-square"></Icon>
        </ButtonAction>
      </div>
    );
  };

  const goToEdit = (uuid) => {
    navigate(`/adm/provider/update/${uuid}`);
  };

  const listData = async (filter) => {
    if (sortBy) {
      filter.sort = sortBy;
    } else {
      filter.sort = "-createdAt";
    }
    const result = await ProviderServices.listFilter(filter);
    return result;
  };

  const hideColumn = {};
  let resize = null;

  // if (deviceSize < SIZES.md) {
  //   hideColumn.itemCode = false;
  //   resize = false;
  // } else if (deviceSize === SIZES.md) {
  //   hideColumn.itemCode = false;
  // }

  return (
    <>
      <PaginateList
        refresh={forceRefresh}
        filters={filters}
        columns={columns}
        toList={listData}
        hideColum={hideColumn}
        resize={resize}
        resetRefresh={() => setForceRefresh(false)}
      ></PaginateList>
    </>
  );
};

export default ProviderListContainer;
