const urlBase = `${process.env.REACT_APP_API_PROTOCOL}://${
  process.env.REACT_APP_API_HOST
}${
  process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ""
}/api/`;

/** AUTH **/
export const urlGetLoginItemTemp = `${urlBase}v1/items/login`;

export const urlRefreshToken = `${urlBase}v1/user/refresh-token`;
export const urlLogout = `${urlBase}v1/user/logout`;
export const urlGetProfile = `${urlBase}v1/user-s/profile`;

export const urlPreRegisterEmployee = `${urlBase}v1/employeeRequest-s/preRegister`;
export const urlDataUser = `${urlBase}v1/dataUser-s`;

export const urlProviderSecured = `${urlBase}v1/provider-s`;

export const urlCompanySecured = `${urlBase}v1/company-s`;
export const urlCompanyAllSecured = `${urlBase}v1/company-s/all`;

export const urlUploadSecured = `${urlBase}v1/upload-s`;

export const urlProviderLogin = `${urlBase}v1/provider/login`;
export const urlAuthChangePass = `${urlBase}v1/provider-s/changePassword`;

export const urlProductionDataSecured = `${urlBase}v1/productionData-s`;
