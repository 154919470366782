import React from "react";
import CompanyForm from "../../../components/adm/company/companyForm";
import Layout from "../../../components/layouts/verticalLayout";
import { useParams } from "react-router-dom";

const NewCompanyPage = () => {
  let { uuid } = useParams();
  return (
    <Layout
      body={
        <>
          <CompanyForm uuid={uuid} />
        </>
      }
    />
  );
};

NewCompanyPage.propTypes = {};

export default NewCompanyPage;
