import React from "react";
import ChangePass from "../../components/user/changePassword";
import Layout from "../../components/layouts/verticalLayout";

const ChangePassPage = () => {
  return (
    <Layout
      body={
        <>
          <ChangePass />
        </>
      }
    />
  );
};

ChangePassPage.propTypes = {};

export default ChangePassPage;
