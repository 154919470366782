const PATHS = {};

PATHS.LOGIN = "/login";
PATHS.HOME = "/user/detailData";
PATHS.EXTERNAL_CONFIRM_LOGIN = "/authConfirm/:token";

PATHS.SHOW_DATA = "/user/detailData";
PATHS.CHNAGE_PASSWORD = "/user/changePassword";

PATHS.LIST_PROVIDER = "/adm/provider/list";
PATHS.NEW_PROVIDER = "/adm/provider/new";
PATHS.UPDATE_PROVIDER = "/adm/provider/update/:uuid";

PATHS.LIST_COMPANY = "/adm/company/list";
PATHS.NEW_COMPANY = "/adm/company/new";
PATHS.UPDATE_COMPANY = "/adm/company/update/:uuid";

PATHS.LIST_UPLOAD = "/adm/upload/list";
PATHS.NEW_UPLOAD = "/adm/upload/new";

export default PATHS;
