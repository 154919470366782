import React, { useState, useMemo, useEffect } from "react";
import * as DataUploadService from "../../../services/DataUploadService";
import PaginateFilterList from "../../utils/PaginateFilterList";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import moment from "moment-timezone";
import { TYPE_ALMOND } from "../../../constants";
import { roundAndFix, formatearNumero } from "../../../utils/formats";
import { ResumeTitle, ResumeCell } from "./almondList.styled";

const AlmondListContainer = ({ filters, sortBy, errors }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const columns = useMemo(
    () => [
      {
        header: t("data.ticket"),
        accessorKey: "numTicket",
        cell: (info) => info.getValue(),
        size: 100,
      },
      {
        header: t("data.date"),
        accessorKey: "date",
        cell: (info) => info.getValue(),
        size: 100,
      },
      {
        header: t("data.company"),
        accessorKey: "company.name",
        cell: (info) => {
          if (info.row.original.company) {
            return info.row.original.company.name;
          }

          return "";
        },
      },
      {
        header: t("data.farm"),
        accessorKey: "farm",
        cell: (info) => info.getValue(),
      },
      {
        header: t("data.variety"),
        accessorKey: "variety",
        cell: (info) => info.getValue(),
      },
      {
        header: t("data.inWeight"),
        accessorKey: "inWeight",
        cell: (info) => Math.round(info.getValue()),
        size: 100,
      },
      {
        header: t("data.rdtoPep"),
        accessorKey: "rdtoPep",
        cell: (info) => info.getValue(),
        size: 100,
      },
      {
        header: t("data.humidity"),
        accessorKey: "humidity",
        cell: (info) => info.getValue(),
        size: 80,
      },
      {
        header: t("data.correctedWeight"),
        accessorKey: "correctedWeight",
        cell: (info) => Math.round(info.getValue()),
        size: 100,
      },
      {
        header: t("data.kgPepLiq"),
        accessorKey: "kgPepLiq",
        cell: (info) => Math.round(info.getValue()),
        size: 100,
      },
      {
        header: t("data.transport"),
        accessorKey: "transport",
        cell: (info) => info.getValue(),
      },
      // {
      //   header: t("data.userData"),
      //   accessorKey: "userData",
      //   cell: (info) => info.getValue(),
      // },
    ],
    []
  );

  const exportToExcel = async () => {
    const dataExp = data.map((item) => {
      // const type = ALMOND_TYPE.find((st) => st.code === item.type);
      return {
        A: item.numTicket,
        B: item.date,
        C: item?.company?.name || "",
        D: item.farm,
        E: item.variety,
        F: item.inWeight,
        G: item.rdtoPep,
        H: item.humidity,
        I: item.correctedWeight,
        J: item.kgPepLiq,
        K: item.transport,
        // M: item.userData,
      };
    });

    dataExp.unshift({
      A: t("data.ticket"),
      B: t("data.date"),
      C: t("data.company"),
      D: t("data.farm"),
      E: t("data.variety"),
      F: t("data.inWeight"),
      G: t("data.rdtoPep"),
      H: t("data.humidity"),
      I: t("data.correctedWeight"),
      J: t("data.kgPepLiq"),
      K: t("data.transport"),
      // M: t("data.userData"),
    });

    const filename = `Almond_export_${moment(new Date()).format(
      "DDMMyyyyHHmm"
    )}`;
    const sheetName = "Listado de Artículos";
    var ws = XLSX.utils.json_to_sheet(dataExp, {
      header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M"],
      skipHeader: true,
      UTC: true,
      dateNF: "dd-mm-yyyy hh:mm;@",
    });
    // dateNF:'mm/dd/yyyy;@',cellDates:true, raw: true}
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, filename + ".xlsx");
  };

  const listData = async () => {
    const filter = {
      page: 1,
      pageSize: 10,
      sort: "-createdAt",
      filters: {
        type: TYPE_ALMOND.code,
      },
    };
    const result = await DataUploadService.listFilter(filter);
    setData(result);
    return result;
  };

  useEffect(() => {
    listData();
  }, []);

  const renderCustomResume = (rows) => {
    let totalCasca = 0;
    let totalCorrect = 0;
    let totalPep = 0;
    let humidity = 0;
    let rend = 0;

    for (let index = 0; index < rows.length; index++) {
      const element = rows[index].original;
      totalCasca += element.inWeight;
      totalCorrect += element.correctedWeight;
      totalPep += element.kgPepLiq;
      humidity += element.humidity;
      rend += element.rdtoPep;
    }

    return (
      <>
        <div className="row no-margin">
          <div className="col-6 mb-3">
            <div className="row ">
              <ResumeTitle $first className="col-4 text-center">
                {t("totals")}
              </ResumeTitle>
              <ResumeTitle className="col-4 text-center">
                {t("data.kg")}
              </ResumeTitle>
            </div>
            <div className="row">
              <ResumeCell $first className="col-4 text-center">
                {t("totalsCasca")}:
              </ResumeCell>
              <ResumeCell className="col-4 text-center">
                {formatearNumero(roundAndFix(totalCasca, 2))} Kg
              </ResumeCell>
            </div>
            <div className="row">
              <ResumeCell $first className="col-4 text-center">
                {t("totalsCorrect")}:
              </ResumeCell>
              <ResumeCell className="col-4 text-center">
                {formatearNumero(roundAndFix(totalCorrect, 2))} Kg
              </ResumeCell>
            </div>
            <div className="row">
              <ResumeCell $first className="col-4 text-center">
                {t("totalsLiq")}:
              </ResumeCell>
              <ResumeCell className="col-4 text-center">
                {formatearNumero(roundAndFix(totalPep, 2))} Kg
              </ResumeCell>
            </div>
            <div className="row">
              <ResumeCell $first className="col-4 text-center">
                {t("totalsHum")}:
              </ResumeCell>
              <ResumeCell className="col-4 text-center">
                {(rows.length > 0 ? humidity / rows.length : 0).toFixed(2)}
              </ResumeCell>
            </div>
            <div className="row">
              <ResumeCell $first className="col-4 text-center">
                {t("totalsRdto")}:
              </ResumeCell>
              <ResumeCell className="col-4 text-center">
                {(rows.length > 0 ? rend / rows.length : 0).toFixed(2)}
              </ResumeCell>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="mb-3">
        <PaginateFilterList
          data={data}
          columns={columns}
          customResume={renderCustomResume}
        ></PaginateFilterList>
      </div>
      <div className="row justify-content-center mb-5">
        <div className="col-12 text-center">
          <button
            type="button"
            className="btn btn-md btn-secondary"
            onClick={() => exportToExcel()}
          >
            <i className="fa-solid fa-file-excel icon-button"></i>{" "}
            {t("exportFileExcel")}
          </button>
        </div>
      </div>
    </>
  );
};

export default AlmondListContainer;
