import LoginForm from "./login.view";
import { useNavigate } from "react-router";
import * as UserServices from "../../../services/UserServices";
import PATHS from "../../../constants/paths";

const LoginContainer = () => {
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      const result = await UserServices.login(values.user, values.password);
      console.log(result);
      if (result && result === "OK") {
        navigate(PATHS.SHOW_DATA);
      } else if (result && result === "CHANGE") {
        navigate(PATHS.CHNAGE_PASSWORD);
      }
    } catch (error) {}
  };

  return <LoginForm onSubmit={handleSubmit}></LoginForm>;
};

export default LoginContainer;
