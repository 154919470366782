import React, { useState, useMemo, useEffect } from "react";
import * as DataUploadService from "../../../services/DataUploadService";
import PaginateFilterList from "../../utils/PaginateFilterList";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import moment from "moment-timezone";
import { TYPE_LAGAR } from "../../../constants";
import { roundAndFix, formatearNumero } from "../../../utils/formats";
import { ResumeTitle, ResumeCell } from "./lagarList.styled";

const AlmondListContainer = ({ filters, sortBy, errors }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const columns = useMemo(
    () => [
      {
        header: t("data.ticket"),
        accessorKey: "numTicket",
        cell: (info) => info.getValue(),
        size: 100,
      },
      {
        header: t("data.precinto"),
        accessorKey: "precinto",
        cell: (info) => info.getValue(),
        size: 100,
      },
      {
        header: t("data.date"),
        accessorKey: "date",
        cell: (info) => info.getValue(),
        size: 100,
      },
      {
        header: t("data.company"),
        accessorKey: "company.name",
        cell: (info) => {
          if (info.row.original.company) {
            return info.row.original.company.name;
          }

          return "";
        },
      },
      {
        header: t("data.farm"),
        accessorKey: "farm",
        cell: (info) => info.getValue(),
      },
      {
        header: t("data.variety"),
        accessorKey: "variety",
        cell: (info) => info.getValue(),
      },
      {
        header: t("data.kgEntregues"),
        accessorKey: "inWeight",
        cell: (info) => info.getValue(),
        size: 100,
      },
      {
        header: t("data.rdtoBruto"),
        accessorKey: "rdtoPep",
        cell: (info) => info.getValue(),
        size: 100,
      },
      {
        header: t("data.acidez"),
        accessorKey: "acidez",
        cell: (info) => info.getValue(),
        size: 80,
      },
      {
        header: t("data.humidity"),
        accessorKey: "humidity",
        cell: (info) => info.getValue(),
        size: 80,
      },
      {
        header: t("data.fat"),
        accessorKey: "fat",
        cell: (info) => info.getValue(),
        size: 100,
      },
      // {
      //   header: t("data.invoice"),
      //   accessorKey: "invoice",
      //   cell: (info) => info.getValue(),
      //   size: 100,
      // },
      // {
      //   header: t("data.userData"),
      //   accessorKey: "userData",
      //   cell: (info) => info.getValue(),
      // },
    ],
    []
  );

  const exportToExcel = async () => {
    const dataExp = data.map((item) => {
      // const type = ALMOND_TYPE.find((st) => st.code === item.type);
      return {
        A: item.numTicket,
        B: item.precinto,
        C: item.date,
        D: item?.company?.name || "",
        E: item.farm,
        F: item.variety,
        G: item.inWeight,
        H: item.rdtoPep,
        I: item.acidez,
        J: item.humidity,
        K: item.fat,
        // L: item.invoice,
        // M: item.userData,
      };
    });

    dataExp.unshift({
      A: t("data.ticket"),
      B: t("data.precinto"),
      C: t("data.date"),
      D: t("data.company"),
      E: t("data.farm"),
      F: t("data.variety"),
      G: t("data.kgEntregues"),
      H: t("data.rdtoBruto"),
      I: t("data.acidez"),
      J: t("data.humidity"),
      K: t("data.fat"),
      // L: t("data.invoice"),
      // M: t("data.userData"),
    });

    const filename = `Lagar_export_${moment(new Date()).format(
      "DDMMyyyyHHmm"
    )}`;
    const sheetName = "Listado de Artículos";
    var ws = XLSX.utils.json_to_sheet(dataExp, {
      header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"],
      skipHeader: true,
      UTC: true,
      dateNF: "dd-mm-yyyy hh:mm;@",
    });
    // dateNF:'mm/dd/yyyy;@',cellDates:true, raw: true}
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, filename + ".xlsx");
  };

  const listData = async () => {
    const filter = {
      page: 1,
      pageSize: 10,
      sort: "-createdAt",
      filters: {
        type: TYPE_LAGAR.code,
      },
    };
    const result = await DataUploadService.listFilter(filter);
    setData(result);
    return result;
  };

  useEffect(() => {
    listData();
  }, []);

  const renderCustomResume = (rows) => {
    let totalKg = 0;
    let rend = 0;

    for (let index = 0; index < rows.length; index++) {
      const element = rows[index].original;
      totalKg += element.inWeight;
      rend += element.rdtoPep;
    }

    return (
      <>
        <div className="row no-margin">
          <div className="col-6 mb-3">
            <div className="row ">
              <ResumeTitle $first className="col-4 text-center">
                {t("totals")}
              </ResumeTitle>
              <ResumeTitle className="col-4 text-center">
                {t("data.kg")}
              </ResumeTitle>
            </div>
            <div className="row">
              <ResumeCell $first className="col-4 text-center">
                {t("totalsKg")}:
              </ResumeCell>
              <ResumeCell className="col-4 text-center">
                {formatearNumero(roundAndFix(totalKg, 2))} Kg
              </ResumeCell>
            </div>
            <div className="row">
              <ResumeCell $first className="col-4 text-center">
                {t("totalsRdBr")}:
              </ResumeCell>
              <ResumeCell className="col-4 text-center">
                {formatearNumero(roundAndFix(rend / rows.length, 2))} Kg
              </ResumeCell>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="mb-3">
        <PaginateFilterList
          data={data}
          columns={columns}
          customResume={renderCustomResume}
        ></PaginateFilterList>
      </div>
      <div className="row justify-content-center mb-5">
        <div className="col-12 text-center">
          <button
            type="button"
            className="btn btn-md btn-secondary"
            onClick={() => exportToExcel()}
          >
            <i className="fa-solid fa-file-excel icon-button"></i>{" "}
            {t("exportFileExcel")}
          </button>
        </div>
      </div>
    </>
  );
};

export default AlmondListContainer;
