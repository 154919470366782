import React from "react";
import ProviderForm from "../../../components/adm/provider/providerForm/providerForm.container";
import Layout from "../../../components/layouts/verticalLayout";
import { useParams } from "react-router-dom";

const NewProviderPage = () => {
  let { uuid } = useParams();
  return (
    <Layout
      body={
        <>
          <ProviderForm uuid={uuid} />
        </>
      }
    />
  );
};

NewProviderPage.propTypes = {};

export default NewProviderPage;
