import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import CompanyForm from "./companyForm.view";
import * as CompanyService from "../../../../services/CompanyService";
import { useNavigate } from "react-router-dom";
import PATHS from "../../../../constants/paths";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import { useTranslation } from "react-i18next";

const CompanyFormContainer = ({ uuid }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [initValues, setInitValues] = useState({});
  const [data, setData] = useState(null);

  const setInitialValues = useCallback(async (company) => {
    const data = {
      cif: company.cif,
      name: company.name,
    };
    setInitValues(data);
  }, []);

  const loadData = useCallback(async () => {
    if (uuid) {
      const result = await CompanyService.getCompany(uuid);
      if (result) {
        setInitialValues(result);
        setData(result);
      }
    }
  }, [setInitialValues, uuid]);

  useEffect(() => {
    loadData();
  }, []);

  const handleSubmit = async (values) => {
    let title = "";
    let text = "";
    let icon = "";

    try {
      const okAction = () => {
        navigate(PATHS.LIST_COMPANY);
      };

      let result;
      if (uuid) {
        result = await CompanyService.updateCompany(values, uuid);
      } else {
        result = await CompanyService.register(values);
      }
      if (result) {
        title = t("forms.company.messages.register.title");
        text = t("forms.company.messages.register.success");
        icon = "success";
      }
      showDialog(title, text, icon, false, "OK", false, okAction);
    } catch (error) {
      // console.log("Error", error);
    }
  };

  return (
    <CompanyForm
      initialValues={initValues}
      onSubmit={handleSubmit}
      edit={uuid ? true : false}
    />
  );
};

CompanyFormContainer.propTypes = {};

export default CompanyFormContainer;
