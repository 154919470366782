import { store } from "../store/index";
import { filterData } from "../store/actions/DataUploadActions";

/**
 * List items
 */
export const listFilter = async (filter) => {
  const result = await store.dispatch(filterData(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
