import styled from "styled-components";

export const TotalWeight = styled.div`
  color: ${({ $error }) => ($error ? "red" : "#000")};
`;

export const ButtonType = styled.button`
  border: ${({ $isSelected }) =>
    $isSelected ? "1px solid #fab442" : "1px solid #203b34"};
  font-size: 1.5rem;
  font-weight: bold;
  background: ${({ $isSelected }) => ($isSelected ? "#fab442" : "#203b34")};
  color: ${({ $isSelected }) => ($isSelected ? "#fff" : "#fab442")};
  letter-spacing: 2px;
  margin-left: 10px;
  width: 300px;
  padding-top: 20px;
  padding-bottom: 20px;

  &:hover {
    background: #fab442;
    color: #fff;
    border: 1px solid #fab442;
  }
`;
