import * as Yup from "yup";

export const dataModel = {
  name: "",
  cif: "",
  // companiesUuids: "",
};

export const validationDataModel = (edit) => {
  return Yup.object().shape({
    name: Yup.string().required("forms.validations.required"),
    cif: Yup.string().required("forms.validations.required"),
    // companiesUuids: Yup.string().required("forms.validations.required"),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
