import { store } from "../store/index";
import { filterUploadAdm, createUpload } from "../store/actions/UploadActions";

/**
 * List items
 */
export const listFilter = async (filter) => {
  const result = await store.dispatch(filterUploadAdm(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

export const register = async (file, typeSelected) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("type", Number(typeSelected.value));
  const result = await store.dispatch(createUpload(formData));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};
