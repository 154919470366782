import React from "react";
import UploadList from "../../../components/adm/upload/uploadFilterList";
import Layout from "../../../components/layouts/verticalLayout";

const UploadListPage = () => {
  return (
    <Layout
      body={
        <>
          <UploadList />
        </>
      }
    />
  );
};

UploadListPage.propTypes = {};

export default UploadListPage;
