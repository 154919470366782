import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { IconStyled } from "./styled";
import { AuthContainer } from "../../../../../security/auth/auth.container";
import { useNavigate } from "react-router";
import PATHS from "../../../../../../constants/paths";
import { Can, AbilityContext } from "../../../../../security/permissions/Can";
import { USER_PERMISSIONS } from "../../../../../security/permissions/rolesPermission";
import { useSelector } from "react-redux";
import { getAuthUserSelector } from "../../../../../../store/selectors/authSelector";

const UserMenu = () => {
  const { t } = useTranslation();
  const auth = AuthContainer.useContainer();
  const navigate = useNavigate();
  const context = useContext(AbilityContext);
  const user = useSelector(getAuthUserSelector);

  const onClick = async (key) => {
    switch (key) {
      case "closeSession":
        logout();
        break;
      case "home":
        navigate(PATHS.SHOW_DATA);
        break;
      case "upload":
        navigate(PATHS.LIST_UPLOAD);
        break;
      case "provider":
        navigate(PATHS.LIST_PROVIDER);
        break;
      case "company":
        navigate(PATHS.LIST_COMPANY);
        break;
      default:
        break;
    }
  };

  const logout = async () => {
    auth.logout();
    if (!user.userName) {
      openCenteredPopup(
        `https://login.microsoftonline.com/99dbb24d-723f-44f0-b082-24b8ce4784d4/oauth2/logout`,
        `logout azureAd`,
        150,
        150
      );
    }
  };

  return (
    <>
      <Menu
        menuButton={
          <MenuButton>
            <i className="fa-solid fa-bars"></i>
          </MenuButton>
        }
        direction="bottom"
        align="end"
        position="anchor"
        viewScroll="auto"
        arrow={true}
        // gap={12}
      >
        <MenuItem
          value="home"
          onClick={(e) => {
            onClick(e.value);
          }}
        >
          <IconStyled className="fa-solid fa-house" />
          {t("home")}
        </MenuItem>
        <Can do={USER_PERMISSIONS.LIST_PROVIDER}>
          <MenuItem
            value="provider"
            onClick={(e) => {
              onClick(e.value);
            }}
          >
            <IconStyled className="fa-solid fa-user-tie" />
            {t("provider")}
          </MenuItem>
        </Can>
        <Can do={USER_PERMISSIONS.LIST_COMPANY}>
          <MenuItem
            value="company"
            onClick={(e) => {
              onClick(e.value);
            }}
          >
            <IconStyled className="fa-solid fa-building" />
            {t("company")}
          </MenuItem>
        </Can>
        <Can do={USER_PERMISSIONS.UPLOAD}>
          <MenuItem
            value="upload"
            onClick={(e) => {
              onClick(e.value);
            }}
          >
            <IconStyled className="fa-solid fa-file-arrow-up" />
            {t("upload")}
          </MenuItem>
        </Can>
        <MenuItem
          value="closeSession"
          onClick={(e) => {
            onClick(e.value);
          }}
        >
          <IconStyled className="fa-solid fa-right-from-bracket" />
          {t("close_session")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;

export const openCenteredPopup = (url, title, w, h) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
      scrollbars=no,
      toolbar=no,
      location=no,
      directories=no,
      status=no,
      menubar=no,
      resizable=no,
      copyhistory=no,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
  );

  newWindow?.focus();

  setTimeout(() => {
    newWindow.close();
  }, 5000);
};
