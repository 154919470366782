import _ from "lodash";
import { axios } from "./axios";

function urlWithParams(url, params) {
  if (params) {
    const urlParams = url.match(/{param}/g) || [];
    if (params instanceof Array) {
      params.forEach((value, index) => {
        if (index < _.size(urlParams)) {
          url = _.replace(url, "{param}", value);
        } else {
          url = `${url}/${value}`;
        }
      });
    } else {
      if (_.size(urlParams)) {
        url = _.replace(url, "{param}", params);
      } else {
        url = `${url}/${params}`;
      }
    }
  }
  return url;
}

/**
 * Call api post request.
 * @param {*} url
 * @param {*} obj
 * @param {*} params
 * @param {*} sendProcessToken
 */
export const apiPost =
  (url, obj, params, loading = false) =>
  () => {
    return axios({
      method: "post",
      url: urlWithParams(url, params),
      data: obj,
      headers: { "Content-type": "application/json", loading },
    })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((errorResponse) => {
        throw errorResponse.response;
      });
  };

/**
 * Call api GET request.
 * @param {*} url
 * @param {*} params
 * @param {*} sendProcessToken
 */
export const apiGet =
  (url, params, loading = false) =>
  () => {
    try {
      return axios({
        method: "get",
        url: urlWithParams(url, params),
        headers: { "Content-type": "application/json", loading },
      })
        .then((response) => {
          if (response.status && response.status === 200) {
            return response.data;
          }
        })
        .catch((errorResponse) => {
          return errorResponse;
        });
    } catch (error) {
      return error;
    }
  };

/**
 * Call api GET request.
 * @param {*} url
 * @param {*} params
 * @param {*} sendProcessToken
 */
export const apiGetFile =
  (url, params, loading = false) =>
  () => {
    try {
      return axios({
        method: "get",
        url: urlWithParams(url, params),
        responseType: "blob",
        headers: { "Content-type": "application/json", loading },
      })
        .then((response) => {
          if (response.status && response.status === 200) {
            return response.data;
          }
        })
        .catch((errorResponse) => {
          return errorResponse;
        });
    } catch (error) {
      return error;
    }
  };

/**
 * Call api post request.
 * @param {*} url
 * @param {*} obj
 * @param {*} params
 * @param {*} sendProcessToken
 */
export const apiPut =
  (url, obj, params, loading = false) =>
  () => {
    return axios({
      method: "put",
      url: urlWithParams(url, params),
      data: obj,
      headers: { "Content-type": "application/json", loading },
    })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((errorResponse) => {
        throw errorResponse.response;
      });
  };

export const apiDelete =
  (url, params, loading = false) =>
  () => {
    try {
      return axios({
        method: "delete",
        url: urlWithParams(url, params),
        headers: { "Content-type": "application/json", loading },
      })
        .then((response) => {
          if (response.status && response.status === 200) {
            return response.data;
          }
        })
        .catch((errorResponse) => {
          return errorResponse;
        });
    } catch (error) {
      return error;
    }
  };

/**
 * Call api GET request.
 * @param {*} url
 * @param {*} params
 * @param {*} sendProcessToken
 */
export const apiGetExternal = (url, params) => () => {
  try {
    return axios({
      method: "get",
      url: urlWithParams(url, params),
      headers: { "Content-type": "application/json" },
    })
      .then((response) => {
        if (response.status && response.status === 200) {
          return response.data;
        }
      })
      .catch((errorResponse) => {
        return errorResponse;
      });
  } catch (error) {
    return error;
  }
};

/**
 * Call api GET request.
 * @param {*} url
 * @param {*} params
 * @param {*} sendProcessToken
 */
export const apiPostFile =
  (url, formData, params, loading = false) =>
  () => {
    try {
      return axios({
        method: "post",
        url: urlWithParams(url, params),
        data: formData,
        headers: { "Content-Type": "multipart/form-data", loading },
      })
        .then((response) => {
          if (response.status && response.status === 200) {
            return response.data;
          }
        })
        .catch((errorResponse) => {
          return errorResponse;
        });
    } catch (error) {
      return error;
    }
  };
