import React from "react";
import UploadForm from "../../../components/adm/upload/uploadForm";
import Layout from "../../../components/layouts/verticalLayout";
import { useParams } from "react-router-dom";

const NewUploadPage = () => {
  let { uuid } = useParams();
  return (
    <Layout
      body={
        <>
          <UploadForm uuid={uuid} />
        </>
      }
    />
  );
};

NewUploadPage.propTypes = {};

export default NewUploadPage;
